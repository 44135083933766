// Updated Translate Container to functional component 6/13/2023

import React, {
  Fragment,
  useState,
  useEffect
} from "react";
import {
  isMobile
} from "react-device-detect";
import Lottie from 'react-lottie';
import uuid from 'uuid';
import * as clipboard from "clipboard-polyfill"
import {
  Button,
  Box,
  TextField,
  Grid,
  Typography,
  Tabs,
  Tab,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Card,
  CardContent,
  Tooltip,
  Zoom,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import "../styles/App.css";
import S3Encoding from "../utils/S3Encoding";
import aniData from "../data/ani-cloud.json";
import getAzurePairs from "../data/pairs-languages-azure";
import trainedModelPairs from "../data/custom-model-pairs";
import API from '../API';
import cciPairs from "../data/pairs-cci"
// MSAL Imports
import { useAccount, useMsal } from "@azure/msal-react";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// *************************************************************************
// Overall Settings

// Translation limit settings
const textMax = 50000;

// Timing settings
const intervalInMs = 1000; // prod should be 1000
const intervalInMsDrop0 = 2000; // prod should be 2000
const intervalInMsDrop1 = 5000; // prod should be 5000
//const intervalInMsPlus0 = 10000; // prod should be 10000 // keep in sync
//const intervalInMSPlus1 = 50000; // prod should be 50000 // keep in sync
//const intervalInMSPlus2 = 60000; // prod should be 60000 // keep in sync
const maxInMs = 900000; // prod should be 300000
const maxInMsDeltaDrop0 = 30000; // prod should be 10000
const maxInMsDeltaDrop1 = 180000; // prod should be 60000
const maxInMsDeltaDrop2 = 360000; // prod should be 120000
const maxInMsDeltaDrop3 = 540000; // prod should be 180000
const maxInMsDeltaDrop4 = 720000; // prod should be 240000

// UI settings
const textTab = 0;
const docTab = 1;
const resultTab = 2;
const basicModelTab = 0;
const advancedModelTab = 1;
const toLabelDefault = "Translation Results";
const toLabelError = "Translation Challenge";
const toLabelProgress = "In Progress";
const toLabelTranslation = "Translation";
const langSourceSelect = "lss";
const langTargetSelect = "lts";
const langCciSelect = "lcs";   // CCI deprecated, leaving relevant code in case we ever need to re-enable it. Commented out rendering code.
const availableModelSelect = "ams";
const defaultTargetLangName = "Target Language";
const defaultSourceLangName = "Source Language";
const hasImageTranslate = true; // Use in payload when checkbox is enabled for image translation (1/2025)

// Animation settings
const aniOptions = {
  loop: true,
  autoplay: true,
  animationData: aniData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Transition settings
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// *************************************************************************
// Overall Error Messages
// If these are added/deleted/changed than the guide-support needs to be updated

const errorMsgDefault = "Uh oh! We had a translation challenge. [default]\n\nFirst, please checkout out our Translation Tips & FAQs - If you are still unsure, please contact us at LillyTranslate@lilly.com\n\nIf you are able to share the document or text you are trying to translate, it may help us to resolve your translation challenge. If you cannot, please let us know what translation criteria you are using and a description of what you are trying to translate. Thank you!";
// Stage 1 should support PDF, so remove msg when transition is done
const errorMsgTime = "Uh oh! We had a translation challenge. [time]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears we ran out of time for your translation request. This usually means that there was more content than we could handle at this time. The amount of content is related to the total number of characters and complexity. It is not related to the file size. We recommend trying to reduce how much content is in the document or text and then trying again. For example, a .docx file can be split in half or a .xlsx file could be translated with fewer sheets. Please let us know about this translation challenge so we can learn from it and improve our performance. Thank you!";
const errorMsgText = "Uh oh! We had a translation challenge. [text]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears you are trying to translate more text than we can currently support. Currently we can only support a maximum length of 5000 characters. Please try removing some content from your text and try again. Additionally, at the top of the text area there is label that looks simliar to 'Text to Translate (123/5000)'. The '123' in this example tells you how many characters you have used so far. If you are still having a challenge with the text, please contact us with the text you are trying to translate. Thank you!"
const errorMsgFile = "Uh oh! We had a translation challenge. [file]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears your translation request was a file type we do not support yet. Currently, we only support files that are .docx, .pptx, .xlsx and .txt. If this is a file type you would like us to support, please contact us so we can consider adding it to our roadmap. Thank you!";
const errorMsgFileZero = "Uh oh! We had a translation challenge. [zero]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears your translation request was with an empty file with a size of 0 KB. Please use a file that has content inside of it. If you are still having a challenge with the file, please contact us with the file you are trying to translate. Thank you!";
const errorMsgUpload = "Uh oh! We had a translation challenge. [upload]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears we had a challenge uploading your content for translation. This is an uncommon challenge, please contact us as soon as possible so we may look into this further.\n\nIf you are able to share the document or text you are trying to translate, it may help us to resolve your translation challenge. If you cannot, please let us know what translation criteria you are using and a description of what you are trying to translate. Thank you!";
const errorMsgName = "Uh oh! We had a translation challenge. [name]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears you are trying to upload a file that has a file name with characters we do not support yet. Try renaming the file and uploading again. If you are still having a challenge with the file, please contact us with the name of the file you are trying to translate. Thank you!";
const errorMsg422 = "Uh oh! We had a translation challenge. [422]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIf you are able to share the document or text you are trying to translate, it may help us to resolve your translation challenge. If you cannot, please let us know what translation criteria you are using and a description of what you are trying to translate. Thank you!";
const errorMsgCopy = "Uh oh! We had a translation challenge. [copy]\n\nPlease contact us at LillyTranslate@lilly.com\n\nIt appears copying to the clipboard is not compatible with your browser version. Please contact us with your browser version and the type of mobile device if applicable. Thank you!";


export default function Translate(){
  
  // State Variables
  const [userID, setUserID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userCountry, setUserCountry] = useState("");

  const [UIDcaptured, setUIDcaptured] = useState(false);

  const [fromLangCode, setFromLangCode] = useState("");
  const [toLangCode, setToLangCode] = useState("");
  const [fromLangName, setFromLangName] = useState(defaultSourceLangName);
  const [toLangName, setToLangName] = useState(defaultTargetLangName);
  const [cciChoice, setCciChoice] = useState("ANY");

  const [disableLanguageSwap, setDisableLanguageSwap] = useState(true);

  const [disableAdvTranslation, setDisableAdvTranslation] = useState(false);
  const [disableBasicTranslation, setDisableBasicTranslation] = useState(false);

  const [fromText, setFromText] = useState("");
  const [fromLabel, setFromLabel] = useState(`Text to Translate (0/${textMax})`);
  const [fromFileName, setFromFileName] = useState("");
  const [fromFileExt,setFromFileExt] = useState("");
  const [fromFile, setFromFile] = useState(null);

  //TODO: Investigate the need for the following 3 variables:
  const [uploadKey, setUploadKey] = useState("");
  const [downloadKey, setDownloadKey] = useState("");
  const [downloadFailKey, setDownloadFailKey] = useState("");

  const [toText, setToText] = useState("");
  const [toLabel, setToLabel] = useState(toLabelDefault);
  const [toFileUrl, setToFileUrl] = useState("");
  const [toFailFileUrl, setToFailFileUrl] = useState("");

  const [outFn, setOutFn] = useState("");
  
  const [disableTextTranslate, setDisableTextTranslate] = useState(true);
  const [disableTextCopy, setDisableTextCopy] = useState(true);
  const [disableTextFullScreen, setDisableTextFullScreen] = useState(true);
  const [disableDocTranslate, setDisableDocTranslate] = useState(true);
  const [disableDocDownload, setDisableDocDownload] = useState(true);
  const [disableDocUpload, setDisableDocUpload] = useState(false);

  const [showDownload, setShowDownload] = useState(false);
  const [showCopy, setShowCopy] = useState(true);
  const [showFullText, setShowFullText] = useState(false);

  const [contentTab, setContentTab] = useState(0);
  const [translationTab, setTranslationTab] = useState(0);
  const [activeTranslation, setActiveTranslation] = useState(false);
  const [activeTranslationID, setActiveTranslationID] = useState("");
  const [activeTranslationTime, setActiveTranslationTime] = useState(null);

  // custom model state
  const [modelType, setModelType] = useState("");
  const [categoryID, setCategoryID] = useState("None");
  const [sourceDisabled, setSourceDisabled] = useState(true);
  const [targetDisabled, setTargetDisabled] = useState(true);

  // Array contains all Language Pairs
  const [azureSrcPairs, setSrcAzurePairs] = useState([]);
  const [azureTrgtPairs, setTrgtAzurePairs] = useState([]);

  // Image Translation State (Updated 1/2025)
  const [isPDF, setIsPDF] = useState(false);
  const [isImageTranslate, setIsImageTranslate] = useState(false);

  // MSAL STATE HOOKS
  // fetch our Public Client Application instance
  const {instance} = useMsal();
  // Fetch current Active Account
  const activeAccount = useAccount()
  // Msal react boolean authentication hook

  // Called whenever activeAccount changes
  useEffect(() => {
    // getAzurePairs functions come from pairs-language-azure.js, already include try/catch for hardcoded values if necessary
    getAzurePairs.getSrcAzurePairs().then((res0) => {
      setSrcAzurePairs(res0)
    })

    getAzurePairs.getTrgtAzurePairs().then((res1) => {
      setTrgtAzurePairs(res1)
    })

    // ID Handling for CA accounts using translate
    if (String(activeAccount.username).includes("-CA")) {
      setUserID(String(String(activeAccount.username).split('-CA')[0]));
    }
    else {
      setUserID(activeAccount.idTokenClaims.employee_id);
    }
    
    setUserEmail(activeAccount.username);
    setUserFullName(activeAccount.name);

    // console.log(activeAccount.idTokenClaims.employee_id);
    // console.log(activeAccount.username);
    // console.log(activeAccount.name);
    // console.log("ABOVE IS THE ACTIVE ACCOUNT INFO FOR LOGGING");

    // Get user country info
    try {
      fetch("https://ipapi.co/country_name")
        .then(res => res.text())
        .then(
          (result) => {
            setUserCountry(result);
          },
          (error) => {
            console.error(error);
            setUserCountry("LillyDefault");
          }
        )
    } catch (e) {
      // ignore this error if it occurs
      console.error(e.message);
    }
  }, [activeAccount])


  // Called whenever userID actually gets updated and can be sent through for API calls
  useEffect(() => {
    setUIDcaptured(true);
    
  }, [userID])

  // Called when one of the dependencies change
  useEffect(() => {
    handleValidateTabs();
  }, [fromLangCode, toLangCode, contentTab, fromFileName, fromFileExt, fromFile, translationTab, UIDcaptured, activeTranslation]);
  
  useEffect(() => {
    handleValidateTabsDelayed();
  }, [fromText, fromLabel]);


  // *************************************************************************
  // Handle events

  // Populate drop downs
  const handleGenerateOptions = (opts) => {
    return opts.map(opt => {
      return ( <option value={opt.code} key={opt.key}> {opt.name} </option>);
    });
  }

  // Populate languages drop downs for custom model tab
  const handleGenerateCustomOptions = (opts, id) => {
    return opts.map(opt => {
      if (opt["model type"] === modelType) {
        return ( opt["models"].map(op => {
          if (id === langSourceSelect) {
            return ( <option value={op.scode} key={op.key}> {op.sname} </option>);
          }
          else {
            if (op["scode"] === fromLangCode) {
              return ( op["target langs"].map(ot => {
                return ( <option value={ot.tcode} key={ot.key}> {ot.tname} </option>);
              }));
            }
          }
        }));
      }
    });
  }

  // Populate model type drop down for custom model tab
  const handleGenerateModelType = (opts) => {
    return opts.map(opt => {
      return ( <option>{opt["model type"]}</option>);
    });
  }

  // Check conditions to see if translate button is pressable
  const handleValidateTabs = () => {
    
    const enableTextTranslateButtonCondition = fromLangCode && toLangCode && fromText && fromText.trim() && (activeTranslation === false) && (toLangCode !== fromLangCode) && (UIDcaptured === true);
    const enableDocTranslateButtonCondition = fromLangCode && toLangCode && fromFileName && (activeTranslation === false) && (toLangCode !== fromLangCode) && (UIDcaptured === true);
    
    if (contentTab === textTab) {
      // checking conditions to decide if Translate button is to be pressable
        if (translationTab === basicModelTab) {
          if (enableTextTranslateButtonCondition) {
            setDisableTextTranslate(false);
          }  
          else {
            setDisableTextTranslate(true);
          }
      }
      else {
        if (enableTextTranslateButtonCondition && modelType) {
          setDisableTextTranslate(false);
        }
        else {
          setDisableTextTranslate(true);
        }
      }
    } 
    else if (contentTab === docTab) {
      
      if (activeTranslation === false) {
        setDisableDocUpload(false);
        setDisableAdvTranslation(false);
        setDisableBasicTranslation(false);
      } 
      else {
        setDisableDocUpload(true);
        if (translationTab === basicModelTab) {
          setDisableAdvTranslation(true);
        }
        else {
          setDisableBasicTranslation(true);
        }
      }

      if (translationTab === basicModelTab) {
        // checking conditions to decide if Translate button is to be pressable
        if (enableDocTranslateButtonCondition) {
            setDisableDocTranslate(false);
        } 
        else {
          setDisableDocTranslate(true);
        }
      }
      else {
        if (enableDocTranslateButtonCondition && modelType) {
            setDisableDocTranslate(false);
        } 
        else {
          setDisableDocTranslate(true);
        }
      }
      
      //if (activeTranslation === false) {
      //  setDisableDocUpload(false);
      //} 
      //else {
      //  setDisableDocUpload(true);
      //}
      // checking conditions to decide if Translate button is to be pressable
      if (enableDocTranslateButtonCondition) {
          setDisableDocTranslate(false);
      } 
      else {
        setDisableDocTranslate(true);
      }
    }

    if ((fromLangCode === "" || fromLangCode === "auto") || toLangCode === "") {
      setDisableLanguageSwap(true);
    }
    else {
      setDisableLanguageSwap(false);
    }
  }

  const handleValidateTabsDelayed = () => {
    setTimeout(handleValidateTabs, 1000);
  }

  const handleResetCriteria = () => {
    // Clear text translate
    setFromLangCode("");
    setToLangCode("");
    setFromLangName(defaultSourceLangName);
    setToLangName(defaultTargetLangName);
    setCciChoice("ANY");
  }
  
  const handleResetDocTab = () => {
    // Clear doc translate
    setFromFileName("");
    setFromFileExt("");
    setFromFile(null);
    setUploadKey("");
    setDownloadKey("");
    setDownloadFailKey("");
  }

  const handleResetResultTab = () => {
    setToText("");
    setToLabel(toLabelDefault);
    setDisableTextCopy(true);
    setDisableTextFullScreen(true);
    setDisableDocDownload(true);
    setDisableTextTranslate(true);
    setDisableDocTranslate(true);
    setShowDownload(false);
    setShowCopy(true);
  }

  // Handling a selection from the dropdowns
  const handleChangeLangChoice = langChoiceId => event => {

    // Grab Language Names from element ID
    var el = document.getElementById(langChoiceId);
    var lab = el.options[el.selectedIndex].innerHTML;

    if (langChoiceId === langSourceSelect) {
      setFromLangCode(event.target.value);
      setFromLangName(lab);

      if(translationTab === advancedModelTab) {
        if (event.target.value === "") {
          setTargetDisabled(true);
        }
        else {
          setTargetDisabled(false);
        }
        setToLangCode("");
        setToLangName(defaultTargetLangName);
      }
    } 
    else {
      setToLangCode(event.target.value);
      setToLangName(lab);
    }
  }

  // Handle the swapping of the source and target languages selected
  const handleSwapLangChoices = () => {
    var lss=document.getElementById(langSourceSelect);
    var lts=document.getElementById(langTargetSelect);
    var temp_code;

    // Use temp variable to swap source and target language code values
    temp_code=lss.value;
    lss.value=lts.value;
    lts.value=temp_code;

    // Update the underlying language codes with swapped values
    setFromLangCode(lss.value);
    setToLangCode(lts.value);

    // Swap names
    var lsn=String(fromLangName);
    var ltn=String(toLangName);

    setFromLangName(ltn);
    setToLangName(lsn);

    // Swap texts
    var lst=String(fromText);
    var ltt=String(toText);

    setFromText(ltt);
    setToText(lst);
  }
  
  // fetch a category ID for a custom model and languages selection
  const handleCategoryID = (preRenderedModelType) => {
    for (let i = 0; i < trainedModelPairs.length; i++) {
      if (preRenderedModelType === trainedModelPairs[i]["model type"]) {
        setCategoryID(trainedModelPairs[i]["categoryID"]);
      }
    }
  }
  
  const handleChangeCciChoice = event => {
    setCciChoice(event.target.value);
  }

  const handleChangeModelType = event => {
    setModelType(event.target.value);
    
    if (event.target.value === "") {
      setSourceDisabled(true);
      setCategoryID("None"); // Reset the model type to "None" when no custom model type is selected from the drop-down
    }
    else {
      setSourceDisabled(false);
      handleCategoryID(event.target.value);
    }
    setTargetDisabled(true);
    handleResetCriteria();
  }

  const handleChangeTabChoice = (event, newValue) => {
    setContentTab(newValue);
    handleScrollBottom();
  }

  const handleChangeTranslationTabChoice = (event, newValue) => {
    setTranslationTab(newValue);
    setSourceDisabled(true);  // Bug Fix for custom model language selection reset
    setTargetDisabled(true);  // Resets the custom model language selection drop-downs to force selection in the right order
    handleResetCriteria();
    setModelType(""); // Reset the model type drop-down to blank
    setCategoryID("None"); // Reset the categoryID for the selected model to the default: "None"
    handleScrollBottom();
  }
  
  const handleChangeFromText = event => {
    setFromText(event.target.value);
    setFromLabel(`Text to Translate (${event.target.value.length}/${textMax})`);
  }

  const handleChangeToText = event => {
    setToText(event.target.value);
  }

  const handleChangeUpload = event => {
    
    // Hanlde the new checkbox to determine if a PDF is uploaded (1/2025)
    const file = event.target.files[0];
    const fileType = file.type;

    if (fileType === "application/pdf") {
      setIsPDF(true);
      //setIsImageTranslate(true); Use to auto check the box when pdf uploaded
    } else {
      setIsPDF(false);
      setIsImageTranslate(false); // Uncheck the box if user uploads a non-pdf file, after already uploading a pdf
    }
    // Handle the new checkbox to determine if a PDF is uploaded (1/2025)

    const curFile = event.target.files[0];
    if (curFile === undefined) {
      handleResetDocTab();
      return;
    }

    // Filter for files that are empty
    if (curFile.size === 0) {
      alert("Selected File is Empty");
      handleResetDocTab();
      return;
    }
    
    // Filter for files that are greater than 40MB
    if (curFile.size > 40 * 1000 * 1024) {
      alert("Max Upload Size is 40MB");
      handleResetDocTab();
      return;
    }

    let fileExt = curFile.name.split('.').pop().toLowerCase();

    if (S3Encoding.isASCII(curFile.name) === false) {
      // Remove Non-ASCII characters
      var clean_name = curFile.name.replace(/[\u{0080}-\u{FFFF}]/gu, "");

      // If the filename is only made up of ASCII characters we are going to have to generate a unique filename for them
      if (clean_name.split('.')[0].length === 0) {
        var uique_id = uuid.v4();
        clean_name = "file_" + uique_id + "." + fileExt;
      }
      
      setFromFileName(clean_name);
    }
    else {
      setFromFileName(curFile.name);
    }

    setFromFileExt(fileExt);
    setFromFile(curFile);
  }

  const handleClickTranslate = event => {
    handleTimer(true);

    handleResetResultTab();
    
    setActiveTranslation(true);

    if (contentTab === textTab) {
      // This is done outside the validation function for performance while typing
      if (fromText.length > textMax) {
        handleError(null, errorMsgText);
      } 
      else {
        translateText();
      }
    } 
    else if (contentTab === docTab) {
      translateDoc();
    }
  }

  const handleClickCopy = event => {
    try {
      clipboard.writeText(toText);
    } 
    catch (error) {
      handleError(error, errorMsgCopy);
    }
  }

  const handleClickDownload = event => {
    fetch(toFileUrl)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = String(outFn)
        document.body.appendChild(a);
        if(a.download !== "null")
        {
            a.click();
            // alert('Your Translated file ' + a.download + ' has downloaded!');
        }
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Could not download file.'));

    // *** OG CODE ***
    // ***************
    // if (window.open) {
      // window.open(toFileUrl, '_blank').focus();
    // } else {
      //window.location = toFileUrl;
    // }
  }

  const handleClickCancel = event => {
    window.location.reload();
  }

  const handleCloseFullText = event => {
    setShowFullText(false);
  }

  const handleError = (error, displayMsg) => {
    var errorText = "";
    
    if (error) {
      if (error.message) {
        console.error(error.message);

        errorText = "Uh oh! We had a translation challenge.".concat(" ", error.message).concat(" --- If this error persists please contact LillyTranslate@lilly.com.")
      } else {
        console.error(error);

        if (displayMsg) {
          errorText = displayMsg;
        } else {
          errorText = errorMsgDefault;
        }
      }
    } else {
      if (displayMsg) {
        errorText = displayMsg;
      } else {
        errorText = errorMsgDefault;
      }
    }

    

    setToLabel(toLabelError);
    setToText(errorText);
    setDisableTextCopy(true);
    setDisableTextFullScreen(true);
    setDisableDocDownload(true);
    setContentTab(resultTab);
    setActiveTranslation(false);
    setActiveTranslationID("");

    handleResetDocTab();    
    handleTimer(false);
    handleScrollBottom();
  }

  const handleTranslateID = () => {
    var translationUUID = uuid.v4();
    setActiveTranslationID(translationUUID);

    return translationUUID;
  }

  const handleTimer = (restartTimer) => {
    if (process.env.REACT_APP_TIMER === "on") {
      if (restartTimer) {
        setActiveTranslationTime(Date.now());
      } 
      else {
        let dateDiffSec = ((Date.now() - this.state.activeTranslationTime) / 1000).toFixed(3);
        let dateDiffMin = (dateDiffSec / 60).toFixed(3);
        setActiveTranslationTime(`sec:${dateDiffSec}; min:${dateDiffMin}`);
      }
    }
  }
  
  const handleScrollBottom = () => {
    window.scrollTo(0,document.body.scrollHeight);
  }

  // Setter Function to handle enabling the Checkbox for Image translation (1/2025)
  const handleCheckbox= (event) => {
    setIsImageTranslate(event.target.checked);
  };

  // *************************************************************************
  // Handle Text Translation
  const translateText = async () => {
    try {
      textUpdates(0);
      let tID = handleTranslateID();

      // Handling for Spanish (Spain) specifically for Ethics & Compliance (TODO: Find a better solution than this)
      if ((toLangCode === "es-xn") && (categoryID === "cad23efe-5c2d-4c2e-9010-b486a5f4ed30-EC-CUSTOM")) {
        var apiData = {
          userId: userID,
          userEmail: userEmail,
          userCountry: userCountry,
          sourceLang: fromLangCode,
          targetLang: String("es"),
          cci: cciChoice,
          srcText: fromText,
          categoryID: String("cad23efe-5c2d-4c2e-9010-b486a5f4ed30-EC-es_xn-CUSTOM"),
        };
      }
      // Added to catch M&Q additional custom model (01/2025)
      else if ((fromLangCode === "none") && (categoryID === "12b40ab5-7a7e-40b3-833e-cb9e0dcfc8a6-GENERAL")) { 
        var apiData = {
          userId: userID,
          userEmail: userEmail,
          userCountry: userCountry,
          sourceLang: String("es"),
          targetLang: toLangCode,
          cci: cciChoice,
          srcText: fromText,
          categoryID: String("12b40ab5-7a7e-40b3-833e-cb9e0dcfc8a6-spain-GENERAL"),
        };
      }
      // For all other cases 
      else {
        var apiData = {
          userId: userID,
          userEmail: userEmail,
          userCountry: userCountry,
          sourceLang: fromLangCode,
          targetLang: toLangCode,
          cci: cciChoice,
          srcText: fromText,
          categoryID: categoryID,
        };
      };

      textUpdates(1, tID)
      
      // (Stage 2) Send Data to API to perform Text Translation

      if (categoryID === "None") {
        // Make a basic text API call
        const results = await API.submitText(apiData);
        
        // Handle the case where the source language is automatically detected
        const detectedLanguageCode = results[0].detectedLanguage.language;
        const detectedLanguageName = azureSrcPairs.find(pair => pair.code === detectedLanguageCode)?.name || detectedLanguageCode;
        setFromLangName(detectedLanguageName);
        //setFromLangName(langSourceSelect)({ target: { value: detectedLanguageName } });
        

        // TODO: This will auto upate the source language drop down to the auto detecteded language
        // setFromLangCode(detectedLanguage);
        // setFromLangName(detectedLanguageName);
        // handleChangeLangChoice(langSourceSelect)({ target: { value: detectedLanguage } });


        // Updated to handle the case where the source language is automatically detected (01/2025)
        textUpdates(8, results[0].translations[0].text)
      }
      else {
        // Include category ID in text API call parameters
        const results = await API.submitCustomText(apiData);

        // NOTE: Automatic source language detection is not supported in custom models
        textUpdates(8, results[0].translations[0].text)
      }
    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const textUpdates = (step, data) => {

    let msg0 = `Hello! Your text translation is now in progress!\n\n`;
    let msg1 = `${msg0}[Translating text...]\n`;
    let msg2 = `${msg1}... just letting you know, we are still here translating...`;
    let msg3 = `${msg1}... not to worry, we are translating, this can take up to 15 minutes for very long texts...`;
    let msg4 = `${msg1}... we are good, doing lots of translating...`;
    let msg5 = `${msg1}... no errors, still translating...`;
    let msg6 = `${msg1}... there is about 3 minutes left of available translation time...`;

    switch (step) {
      case 0:
        setShowCopy(true);
        setShowDownload(false);
        setDisableTextCopy(true);
        setDisableTextFullScreen(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);
    
        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);
        // data is the current translation UUID
        setTimeout(() => textUpdates(2, data), 30000);
        
        break;
      case 2:
        if (activeTranslation === true &&
          activeTranslationID === data) {
          setToText(msg2);
          setTimeout(() => textUpdates(3, data), 150000);
        }

        break;
      case 3:
        if (activeTranslation === true &&
          activeTranslationID === data) {
            setToText(msg3);
          setTimeout(() => textUpdates(4, data), 180000);
        }

        break;
      case 4:
        if (activeTranslation === true &&
          activeTranslationID === data) {
            setToText(msg4);
          setTimeout(() => textUpdates(5, data), 180000);
        }

        break;
      case 5:
        if (activeTranslation === true &&
          activeTranslationID === data) {
            setToText(msg5);
          setTimeout(() => textUpdates(6, data), 180000);
        }

        break;
      case 6:
        if (activeTranslation === true &&
          activeTranslationID === data) {
            setToText(msg6);
          setTimeout(() => textUpdates(7, data), 180000);
        }

        break;
      case 7:
        handleError("Timed out", errorMsgTime);

        break;
      case 8:
        setToLabel(toLabelTranslation);
        setToText(data);
        setDisableTextCopy(false);
        setDisableTextFullScreen(false)
        setActiveTranslation(false);
        setActiveTranslationID("");
        setContentTab(resultTab);  

        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

  // *************************************************************************
  // Handle Document Translation
  const translateDoc = async () => {
    try {
      docUpdates(0);
      let tID = handleTranslateID();
      let keys = createKeys(tID);

      // Custom file translation is handled by including the categoryID variable in the S3 folder structure for UI files in V5.0
      // TODO: Update this to use temporary JSON files on the UI file handling code to pass translation job information

      let URLs = await API.submitFile(fromFile, String(keys[0]), String(keys[1]), String(keys[0]));
      // Since the ukey and dkey are the same for translation jobs, just pass the same key twice for both API inputs

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      docUpdates(3, "NULL", URLs);

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createKeys = (tID) => {
    // REMOVE BELOW FROM 5.1 once UI file handling lambda uses metdata instead of paths
    let blankedFromFileName = S3Encoding.removeSpecials(fromFileName);
    let prefixBlankedFromFileName = blankedFromFileName.substr(0, blankedFromFileName.lastIndexOf("."));
    prefixBlankedFromFileName = prefixBlankedFromFileName.split(".").join("_");
    tID = prefixBlankedFromFileName;
    // REMOVE ABOVE FROM 5.1 once UI file handling lambda uses metdata instead of paths

    setOutFn(tID);

    // TODO: Differentiate filenames from source and target to increase back-end flexibility ("filename_fr.ext")
    // Upload and download keys are the same for back-end compatibility purposes

    // TODO: Need to handle the scenario where the user uploads a file with the same name using AUTO language detection to the same target lang
    // This scenario is not a major concern but it could result the user being served the wrong file under very specific circumstances
    // NOTE: THE USER WILL NEVER BE SERVED ANOTHER USERS FILE, rather the user may be served a cached version of their own file with the same name,
    // which may not be the same file they uploaded. This can only occur if a hyper specific set of circumstances were to take place.

    // Handling for Spanish (Spain) specifically for Ethics & Compliance (TODO: Find a better solution than this)
    if ((toLangCode === "es-xn") && (categoryID === "576b855e-9624-4e29-8b53-c6e7fe298d06-EC-CUSTOM")) {
      var uKey = `private/UI/${userID}/576b855e-9624-4e29-8b53-c6e7fe298d06-EC-es_xn-CUSTOM/${fromLangCode}/es/${isImageTranslate}/${tID}.${fromFileExt}`
    } 
    // Added to catch M&Q additional custom model (01/2025)
    else if ((fromLangCode === "none") && (categoryID === "12b40ab5-7a7e-40b3-833e-cb9e0dcfc8a6-GENERAL")) { 
      var uKey = `private/UI/${userID}/12b40ab5-7a7e-40b3-833e-cb9e0dcfc8a6-spain-GENERAL/es/en/${isImageTranslate}/${tID}.${fromFileExt}`;
    }
    // For all other cases
    else {
      var uKey = `private/UI/${userID}/${categoryID}/${fromLangCode}/${toLangCode}/${isImageTranslate}/${tID}.${fromFileExt}`
    };
    // Added variable isImageTranslate to all cases for s3 directory for file uploaded with the uKey that will be passed to the API
      // variable determines if the file is a PDF with embedded images to translate or not (1/2025)

    let downloadFailKey = `${uKey}_FAIL.json`;

    return [uKey, downloadFailKey];
  }

  const docUpdates = (step, data, meta) => {
    let msg0 = `Hello! Your document translation is now in progress!\n\n`;
    let msg1 = `${msg0}[Uploading document ${data}%]\n\n`;
    let msg2 = `${msg0}Document Uploaded\n\n`;
    let msg3 = `${msg2}[Translating document...]\n`;
    let msg4 = `${msg3}... just letting you know, we are still here translating...`;
    let msg5 = `${msg3}... not to worry, we are translating, this can take up to 15 minutes for larger files and scanned PDF's...`;
    let msg6 = `${msg3}... we are good, doing lots of translating...`;
    let msg7 = `${msg3}... no errors, still translating...`;
    let msg8 = `${msg3}... there is about 3 minutes left of available translation time...`;
    let msg9 = `[ ** COMPLETE ** ]\n\nYour translation is complete! Please download it by clicking the button below.\n\nThank you for using Translate :)`;
    let msg10 = `[ ** COMPLETE ** ]\n\nYour translation is complete! Please download it by clicking the button below.\n\nThank you for using Translate :)`;

    switch (step) {
      case 0:
        setShowCopy(false);
        setShowDownload(true);
        setDisableDocDownload(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);

        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);

        break;
      case 2:
        setToText(msg2);

        docUpdates(3);

        break;
      case 3:
        setToText(msg3);

        let dropStatus = [false, false, false, false, false];
        // continuousPollForDoc(intervalInMs, maxInMs, toFileUrl, toFailFileUrl, dropStatus); // NEED TO FIGURE OUT WHY THIS DOESN'T WORK
        continuousPollForDoc(intervalInMs, maxInMs, meta[0], meta[1], dropStatus);

        break;
      case 4:
        setToText(msg4);

        break;
      case 5:
        setToText(msg5);

        break;
      case 6:
        setToText(msg6);

        break;
      case 7:
        setToText(msg7);

        break;
      case 8:
        setToText(msg8);

        break;
      case 9:  
        if (fromFileExt === "txt") {
          setToText(msg9);
        } 
        else {
          setToText(msg10);
        }
        setToLabel(toLabelTranslation);
        setDisableDocDownload(false);
        setActiveTranslation(false);
        setActiveTranslationID("");
        setContentTab(resultTab);
        
        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

  // The first call to this recursive function should be:
    // interval in ms, max time in ms, "", "", [false, false, false, false, false]
  const continuousPollForDoc = async (interval, max, targetUrl, failUrl, dropStatus) => {
    //const that = this;

    // Get the URL for the target download file
    if (targetUrl === "" || targetUrl === null) {
      
      // (STAGE 2) Get Translated File URL.

      setToFileUrl(targetUrl);
    }

    // Get the URL for the fail file
    if (failUrl === "" || failUrl === null) {
      
      // (STAGE 2) Get Translated Fail File URL.
      setToFailFileUrl(failUrl);
    }

    // isFile* is for the translated file
    // isFileFail* is for the fail file (*_FAIL.json)
    var isFileOK = false;
    var isFileFailOK = false;

    // Check if the translated file is done yet
    if (targetUrl) {
      isFileOK = await verifyUrlStatus(targetUrl);
    }

    // If file is done, allow the user to download it
    if (isFileOK === true) {
      docUpdates(9);
      max = 0;
      // Else file is not done, run recursion
    } else {
      // Not reached the time limit
      if (max > 0) {
        // The current max minus the interval
        let newMax = max - interval;

        // If 10 seconds has passed:
        // - change interval to 2 seconds (prod)
        // - update message
        if (dropStatus[0] === false && newMax < (maxInMs - maxInMsDeltaDrop0)) {
          interval = intervalInMsDrop0;
          dropStatus[0] = true;
          docUpdates(4);

          // if 1 minute has passed:
          // - change interval to 5 seconds (prod)
          // - check for server failure
          // - update message
        } else if (dropStatus[1] === false && newMax < (maxInMs - maxInMsDeltaDrop1)) {
          interval = intervalInMsDrop1;
          dropStatus[1] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(5);
          }

          // if 2 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[2] === false && newMax < (maxInMs - maxInMsDeltaDrop2)) {
          dropStatus[2] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(6);
          }

          // if 3 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[3] === false && newMax < (maxInMs - maxInMsDeltaDrop3)) {
          dropStatus[3] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(7);
          }

          // if 4 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[4] === false && newMax < (maxInMs - maxInMsDeltaDrop4)) {
          dropStatus[4] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(8);
          }
        }

        // Do recursion if fileFail does not exist
        if (isFileFailOK === false) {
          setTimeout(() => continuousPollForDoc(interval, newMax, targetUrl, failUrl, dropStatus), interval);
        }
        // time limit reached
      } else {
        handleError("Timed out", errorMsgTime);
        max = 0;
      }
    }
  }

  //TODO: Find a way to obfuscate parts of the URL on the signed S3 URL when getting 404 responses
  const verifyUrlStatus = async (url) => {
    var fileStatus = 0;
    var isOK = false;
    fileStatus = await fetch(url)
      .then(res => res.status)
      .catch(error => {
        console.error("*** Fetch Error ***");
        console.error(error);
      });

    if ((fileStatus >= 200 && fileStatus < 300) || fileStatus === 302) {
      isOK = true;
    } else {
      isOK = false;
    }

    return isOK;
  }

  return (
    <div>
      <Grid container spacing={1} className="translateGrid">
        <Grid item xs={12} sm={isMobile ? 12 : 4}>
          <Card className="translateCard">
            <CardContent>
              <p className="translateCardTitle" color="inherit">
                Translation Options
              </p>
              <Tabs
                value={translationTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTranslationTabChoice}
                centered
              >
                <Tooltip title="Base Translation Model" placement="top-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} disableInteractive arrow>
                  <Tab 
                    label="Basic"
                    disabled={disableBasicTranslation}
                  />
                </Tooltip>
                <Tooltip title="Custom Translation Models" placement="top-end" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} disableInteractive arrow>
                  <Tab
                    label="Custom Models"
                    disabled={disableAdvTranslation}
                  />
                </Tooltip>              
              </Tabs>
              {(activeTranslation === false) && (translationTab === basicModelTab) &&(
                <Fragment>
                  <Grid item xs={12}>
                    <Tooltip title="Source Language" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        id={langSourceSelect}
                        select
                        label={"Translate From"}
                        required
                        value={fromLangCode}
                        onChange={handleChangeLangChoice(langSourceSelect)}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateOptions(azureSrcPairs)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Button 
                      variant="contained"
                      size="small"
                      disabled={disableLanguageSwap}
                      onClick={() => {
                        handleSwapLangChoices();
                      }}
                    >
                        &#x2191; Swap &#x2193;
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Target Language" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        id={langTargetSelect}
                        select
                        label={"Translate To"}
                        required
                        value={toLangCode}
                        onChange={handleChangeLangChoice(langTargetSelect)}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateOptions(azureTrgtPairs)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      id={langCciSelect}
                      select
                      label={"Using CCI"}
                      required
                      value={cciChoice}
                      onChange={handleChangeCciChoice}
                      SelectProps={{
                          native: true,
                      }}
                      className="fullSelectors"
                      >
                      {handleGenerateOptions(cciPairs)}
                    </TextField>
                  </Grid> */}                   
                  {process.env.REACT_APP_TIMER === "on" && (
                    <p>{activeTranslationTime}</p>
                  )}
                </Fragment>
              )}
              {(activeTranslation === false) && (translationTab === advancedModelTab) && (
                <Fragment>
                  <Grid item xs={12}>                   
                    <Tooltip title="Select Model" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        id={availableModelSelect}
                        select
                        label={"Model Type"}
                        required
                        value={modelType}
                        onChange={handleChangeModelType}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateModelType(trainedModelPairs)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Source Language" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        id={langSourceSelect}
                        select
                        label={"Translate From"}
                        required
                        disabled={sourceDisabled}
                        value={fromLangCode}
                        onChange={handleChangeLangChoice(langSourceSelect)}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateCustomOptions(trainedModelPairs, langSourceSelect)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Target Language" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        id={langTargetSelect}
                        select
                        label={"Translate To"}
                        required
                        disabled={targetDisabled}
                        value={toLangCode}
                        onChange={handleChangeLangChoice(langTargetSelect)}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateCustomOptions(trainedModelPairs, langTargetSelect)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info" sx={{ display: "flex", justifyContent: "center" }}>
                      Trained with Lilly data from select domains. <br /> See the "Custom Models" section of the <strong>Guide</strong> for more details!
                    </Alert>
                  </Grid>
                  {/*<Grid item xs={12}>
                    <TextField
                      id={langCciSelect}
                      select
                      label={"Using CCI"}
                      required
                      value={cciChoice}
                      onChange={handleChangeCciChoice}
                      SelectProps={{
                          native: true,
                      }}
                      className="fullSelectors"
                      >
                      {handleGenerateOptions(cciPairs)}
                    </TextField>
                    </Grid>*/}
                </Fragment>
              )}
              {activeTranslation === true && (
                <Fragment>
                  <Lottie
                    options={aniOptions}
                    height={250}
                    width={250}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={isMobile ? 12 : 8}>
          <Card className="translateCard">
            <CardContent>
              <br></br>
              <Tabs
                value={contentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTabChoice}
              >
                <Tab label="Text" />
                <Tab label="Document" />
                <Tab label="Results" />
              </Tabs>
              {contentTab === textTab && (
                <Fragment>
                  <TextField
                    id="contentSource"
                    label={fromLabel}
                    placeholder="Text to Translate"
                    multiline
                    value={fromText}
                    onChange={handleChangeFromText}
                    rows="8"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickTranslate}
                    // disabled={() => {
                    //   if (translationTab === basicModelTab) {
                    //     return disableTextTranslate
                    //   }
                    //   else {
                    //     return true;
                    //   }
                    // }}
                    disabled={disableTextTranslate}
                  >
                    Translate
                  </Button>
                </Fragment>
              )}
              {contentTab === docTab && (
                <Fragment>
                  <label htmlFor="fileUpload">
                    <Typography variant="subtitle1" className="fullTypogUpload">
                      <p>
                        <b>Document to Translate</b>
                      </p>
                        (Word, Excel, PowerPoint, PDF, TXT, CSV, Markdown, HTML, or XLIFF 1.2 files)
                        <p style={{ display: "flex", justifyContent: "center", marginBottom: "-0.5rem" }}>
                          <i>
                            *Note: Scanned PDF are not fully supported at this time, results may vary.
                          </i>  
                        </p>
                        <p style={{ display: "flex", justifyContent: "center", marginTop: "-0.5rem" }}>
                          <i>
                            Embedded Image Translation is ONLY available for PDF, results may vary.
                          </i>
                        </p>
                    </Typography>
                    <input
                      type="file"
                      accept=".pdf, .csv, .html, .htm, .xlf, .xliff, .markdown, .mdown, .mkdn, .md, .mkd, .mdwn, .mdtxt, .mdtext, .rmd, .mthml, .mht, .xls, .xlsx, .msg, .ppt, .pptx, .doc, .docx, .odt, .odp, .rtf, .tsv, .tab, .txt" //".docx, .xlsx, .pptx, .txt, .pdf, .xlf"
                      onChange={handleChangeUpload}
                      id="fileUpload"
                      className="displayNone"
                      disabled={disableDocUpload}
                    />
                    <Tooltip title="40MB Max size" placement="right" startIcon={<CloudUploadIcon />} TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <Button
                        variant="contained"
                        component="span"
                        disabled={disableDocUpload}
                      >
                        Upload File
                        <VisuallyHiddenInput type="file" />
                      </Button>
                  </Tooltip>

                  {/* New checkbox feature in UI (1/2025) */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isImageTranslate}
                        onChange={handleCheckbox}
                        name="isImageTranslate"
                        disabled={!isPDF}
                      />
                    }
                    label="Translate Embedded Image(s)"
                    style={
                      { 
                        display: "flex", 
                        justifyContent: "center", 
                        marginTop: "0.5rem", 
                        marginBottom: "-0.5rem" 
                      }
                    }
                  />
                  {/* New checkbox feature in UI (1/2025) */}

                    <p>{fromFileName}<br/></p>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickTranslate}
                    disabled={disableDocTranslate}
                  >
                    Translate
                  </Button>
                </Fragment>
              )}
              {contentTab === resultTab && (
                <Fragment>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}> 
                  {showCopy === true && (
                    <Fragment>
                      {}
                      <TextField
                        id="contentSource"
                        label={fromLangName}
                        placeholder="Source Text"
                        multiline
                        value={fromText}
                        rows="8"
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </Fragment>
                  )}
                    <TextField
                      id="contentResult"
                      label={toLangName}
                      placeholder={toLabel}
                      multiline
                      value={toText}
                      rows="8"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Box>
                  {showCopy === true && (
                    <Fragment>
                      {}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickCopy}
                        disabled={disableTextCopy}
                      >
                        Copy {toLangName} to Clipboard
                      </Button>
                    </Fragment>
                  )}
                  {showDownload === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickDownload}
                      disabled={disableDocDownload}
                    >
                      Download
                    </Button>
                  )}
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog fullScreen open={showFullText} onClose={handleCloseFullText} TransitionComponent={Transition}>
        <AppBar className="fullEditAppBar">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseFullText} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="fullEditTitle">
              Full Screen Post-Editing
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClickCopy}>
              Copy to Clipboard
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} className="fullEditGrid">
          <Grid item xs={12} sm={6}>
            <TextField
              id="contentSourceFullEdit"
              label="Text to Translate (locked)"
              placeholder="Text to Translate"
              multiline
              value={fromText}
              rows="20"
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="contentTargetFullEdit"
              label="Translation (edit)"
              placeholder="Translated Text"
              multiline
              value={toText}
              onChange={handleChangeToText}
              rows="20"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}